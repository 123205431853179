// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-history-js": () => import("./../../../src/pages/about-us/history.js" /* webpackChunkName: "component---src-pages-about-us-history-js" */),
  "component---src-pages-about-us-idea-js": () => import("./../../../src/pages/about-us/idea.js" /* webpackChunkName: "component---src-pages-about-us-idea-js" */),
  "component---src-pages-about-us-organization-js": () => import("./../../../src/pages/about-us/organization.js" /* webpackChunkName: "component---src-pages-about-us-organization-js" */),
  "component---src-pages-business-brjy-js": () => import("./../../../src/pages/business/brjy.js" /* webpackChunkName: "component---src-pages-business-brjy-js" */),
  "component---src-pages-business-fcjj-js": () => import("./../../../src/pages/business/fcjj.js" /* webpackChunkName: "component---src-pages-business-fcjj-js" */),
  "component---src-pages-business-getai-js": () => import("./../../../src/pages/business/getai.js" /* webpackChunkName: "component---src-pages-business-getai-js" */),
  "component---src-pages-business-jdyy-js": () => import("./../../../src/pages/business/jdyy.js" /* webpackChunkName: "component---src-pages-business-jdyy-js" */),
  "component---src-pages-business-jzfw-js": () => import("./../../../src/pages/business/jzfw.js" /* webpackChunkName: "component---src-pages-business-jzfw-js" */),
  "component---src-pages-business-lhgc-js": () => import("./../../../src/pages/business/lhgc.js" /* webpackChunkName: "component---src-pages-business-lhgc-js" */),
  "component---src-pages-business-wuye-js": () => import("./../../../src/pages/business/wuye.js" /* webpackChunkName: "component---src-pages-business-wuye-js" */),
  "component---src-pages-business-zszx-js": () => import("./../../../src/pages/business/zszx.js" /* webpackChunkName: "component---src-pages-business-zszx-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

